@import '~antd/dist/antd.css';

.logo {
    width: 120px;
    height: 50px;
    
    margin: 16px 24px 16px 0;
    float: left;
  }

  .divLogoNucardapio{
    margin-top: 10px; 
    margin-left: -20px;
    width: 100px;
    
    float: left;
  }

  .Header{
    height: 85px;
    width: 100%;
    position: absolute;
    z-index: 100;
  }

  .Menu{
      line-height: 85px;

  }
  .menu-Haburguer{
    color: #ffffff;
    float: right;
    margin-top: 10px;
  }