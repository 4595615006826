@import '~antd/dist/antd.css';

.botoesPedidoStatus{
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 25px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 20px;
}

.botoesPedidoStatus button{
    
}

.botoesPedidoStatus button:nth-child(1){
    background: #e4b53e;
}

.botoesPedidoStatus button:nth-child(2){
    background: blue;
    color: #FFF !important;
}

.botoesPedidoStatus button:nth-child(3){
    background: rgb(212, 80, 80);
    color: #FFF !important;
}

.botoesPedidoStatus button:nth-child(4){
    background: green;
    color: #FFF !important;
}