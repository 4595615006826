@import "~antd/dist/antd.css";


.paginaInicial {
  @media (max-width: 768px) {
    .bannerInicial {
      height: 200px !important;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media (min-width: 768px) {
    .bannerInicial {
      height: 40vh !important;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .DesAtencao {
    font-weight: bold;
  }
  .bannerInicial .bg {
    background-size: 100% !important;
  }
  .bgBlack {
    background: #fcfcfc;
  }

  .Header {
    height: 85px;
    width: 100%;
    position: fixed;
    z-index: 1000;
  }

  .Menu {
    line-height: 85px;
  }

  .item:last-child {
    margin-bottom: 100px;
  }

  .box {
    border-bottom: 1px dotted #666;
    padding: 20px;
    /* margin: 10px 50px; */
    margin: 0 10px;
    /*margin: 10px 50px;*/
  }

  .renderProdutos {
    color: #000;
  }

  .renderProdutos h1 {
    color: #000;
  }

  .ant-btn-secundary {
    background: rgb(189, 11, 11);
    color: #ffffff;
  }

 

  .colorRed {
    color: red !important;
    font-weight: bold;
  }

  .preco {
    font-size: 1.4em;
    font-weight: bold;
    float: right;
  }

  .qtdItem {
    font-size: 1.8em;
    display: inline-block;
    padding: 0 15px;
  }
  .ant-btn {
    height: 40px !important;
  }

  .renderCardapio h1 {
    color: #333 !important;
    margin-bottom: 5px;
    font-size: 1.2em;
    max-width: 70%;
    display: inline-block;
  }

  .descricaoItem {
    margin-bottom: 5px;
    display: block;
  }

  .btnAddCarrinho {
    text-align: right;
  }

  .addButton,
  .addButton:hover,
  .addButton:visited,
  .addButton:focus {
    color: #000;
    border-color: #e5b53e;
  }

  .divAtencao {
    color: #000;
    background: #e5b53e;
    border-bottom: solid 2px #000;
  }
  .divAtencao h1 {
    border-bottom: none;
  }
  .DescItemCarrinho {
    display: inline-block;
    font-weight: bold;
    width: 73%;
  }

  .btnAdicional {
    float: right;
  }

  

 
}






















#bannerImgProdutos {
  height: 350px !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat !important;
}


.modal{
  .ant-checkbox-group,
.ant-radio-group {
  width: 100%;
}

.ant-radio-wrapper,
.ant-checkbox-group-item {
  width: 100% !important;
  padding: 5px;
  margin: 2px;
  border-bottom: 1px solid #eee;
  white-space: normal;
}
}
.modal * {
  color: #000 !important;
}

.modal .ant-btn {
  color: #fff !important;
}

.descricaoRadio {
  color: #333 !important;
}

.titleOpcoes {
  color: rgb(49, 46, 46) !important;
  margin: 10px 0px;
  padding: 10px;
  margin: 2px;
  background: #eee;
}