@import '~antd/dist/antd.css';

.pgGerenciar{
*{
  text-transform: capitalize;
}

.headerPedidos{
  text-align: center; 
  background: #E4B53D; 
  padding: 10px
}

.headerPedidos div{
  background: #ffffff
}

}