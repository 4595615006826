@import "~antd/dist/antd.css";

.pgPedidos {
  .marginTopo {
    margin-top: 0px;
  }

  .preco{
    float: right;
  }

  .box {
    .titleBox {
      text-align: center;
      font-weight: bold;
      padding: 10px;
    }

    .cor1{
      background: #EEE;
    }
    
    .cor2{
      background: #555;
      color: #FFF;
    }

    .cor3{
      background: rgb(61, 55, 55);
      color: #FFF;
    }
    .bodyBox {
      padding-top: 30px;
      height: 83vh;
      overflow: auto;
    }
  }

  .headerPedidos {
    text-align: center;
    background: #e4b53d;
    padding: 10px;
  }

  .headerPedidos div {
    background: #ffffff;
  }

  .pedidos {
    padding: 10px;
  }

  .pedidosBorda {
    border: 1px solid #ccc;
  }

  button {
    color: #000 !important;
  }
  .pedidos .header {
    border-bottom: 1px solid #eee;
  }

  .pedidos .header h1 {
    font-size: 18pt;
    margin-top: 10px;
  }

  .ant-modal-content h1 {
    font-size: 14pt;
  }

  .pedidos .body {
    padding: 10px;
  }

  .carrinho {
    border-bottom: 1px solid #eee;
  }

  .botoesAcao {
    background: #eee;
  }

  .pedidos button .anticon {
    font-size: 30px;
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }

    @page {
      margin: 0;
    }
    body {
      margin: 1.6cm;
    }
  }
}
