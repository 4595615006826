@media(min-width:768px){
  .whatsapp{
    position: fixed !important;
    bottom: 0px !important;
    border-radius: 0px !important;
    width: 100% !important;
    z-index: 10000;
    left: 0px;
    padding: 50px !important;
  }


  /*
  .whatsapp{
    top: 10%  !important;
    right: 5%  !important;
    z-index: 2000;
    
    
    background: #e5b53e !important;
    border: 0px !important;
    border-radius: 50px !important;
    padding: 20px !important;
    height: 80px !important;
  }
  
*/
  .whatsapp span{
    position: relative;
    top: -30px;
  
  }
  .logoEmpresa{
    position: absolute;
    z-index: 9;
    width: 250px;
    left: 50%;
    margin-left: -125px;
    margin-top: 8%;
  }
  

}

@media(max-width:768px){
  .logoEmpresa{
    position: absolute;
    z-index: 9;
    width: 250px;
    left: 50%;
    margin-left: -125px;
    margin-top: 2%;
  }
}





.colunaPrincipal section{  
  background-size: 100%;
}

.margin5{
  /*
  margin: 5%;
  */
}

.padding5{
  padding: 5%;
}

h1.title{
      /* background: #A92D0B; */
      color: #000 !important;
      padding: 20px;
      font-weight: bold;
      border-bottom: solid 2px #e5b53e;
      /* text-align: center; */
      margin: 0 10px;
      font-size: 1.4em;
}


ul li{
  color: #000;
}



.ant-btn-primary{
  border: 0px !important;
  background: #e5b53e !important;
}

.ant-layout-header,.ant-menu-dark{
  
}

.ant-menu-item-selected{
  border-bottom: 2px solid #FFF !important;
  background: transparent !important;  
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


@media(min-width:0px) and (max-width:767px){
  .whatsapp{
    position: fixed !important;
    bottom: 0px !important;
    border-radius: 0px !important;
    width: 100% !important;
    z-index: 10000;
    left: 0px;
    padding: 50px !important;
  }

  .whatsapp .anticon{
    margin-top: -40px;
  }

  .whatsapp span{
    position: relative;
    top: -25px;
  }

  .logoEmpresa{
    margin-top: 80px;
  }

}

.ant-tabs-nav-wrap {
  position: relative;
  z-index: 999999999;
}