@import '~antd/dist/antd.css';

.pgModalPedidoX{
 .preco{
    font-size: 1em !important;
    float: none;
}

@media print
{
     *{
        border: none !important;
        padding: 0px !important;
        margin: 0px !important;
    }
     .ant-modal-body{
        border: none !important;
        margin: 0px !important;
        margin-top: -100px !important;
        width: 300px;
        padding: 5mm !important;
    }

     .ant-modal-content{
        display: block;
        border: none !important;
        padding: 0px !important;
        margin: 0px !important;
        box-shadow: none !important;
    }
    
     .print .botoesPedidoStatus{
        border: none !important;
        padding: 0px !important;
        margin: 0px !important;
    }

     .print button{
        display: none !important;
    }

     * {
        background:transparent !important;
        color:#000 !important;
        text-shadow:none !important;
        filter:none !important;
        -ms-filter:none !important;
    }

     body {
        margin:0;
        padding:5mm;
        line-height: 1.4em;
    }

     header {
        display: none;
    } 

     footer {
        display: none;
    }

     .content {
        display: block;
    }

     img {
        max-width: 100%;
    }
}
}